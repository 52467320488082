import { useState, useEffect } from 'react'
import axios from '../tools/axiosWrapper'
import { motion } from 'framer-motion'
import { randomInt } from 'd3'
import { Time } from './time/time'
import { Map } from './map/map'
import { Head } from './head'

import { Side } from './side'
import { useTheme } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import { color } from 'd3-color'

import './dashboard.css'
import _ from 'lodash'
import { Stack, Typography, Box } from '@mui/material'
const Dashboard = () => {
  const theme = useTheme()

  const [dashstate, setDashstate] = useState('Ohio')
  const [dashcounty, setDashcounty] = useState('')
  const [states, setStates] = useState([])

  useEffect(() => {
    axios
      .get('/dashboard/states')
      .then((res) => form.setValue('options.states', res.data))
  }, [])

  const form = useForm({
    defaultValues: {
      current: {
        state: null,
        county: null,
        zip: null,
        display: 'total',
        sound: false,
      },
      options: {},
    },
  })
  let c2 = color(theme.palette.background.light)

  c2.opacity = 0.99
  return (
    <>
      <Stack
        direction={'row'}
        sx={{
          width: '100vw',
          minHeight: '100vh',
          background: theme.palette.background.default,
        }}
      >
        <Stack
          direction={'row'}
          sx={{ position: 'fixed', overflowY: 'hidden' }}
        >
          <Side states={states} form={form} />
        </Stack>

        <Box
          sx={{
            left: '280px',
            width: 'calc(100vw - 250px)',
            height: '45vh',
            position: 'absolute',
          }}
        >
          <Map form={form} state={form.watch('current.state')} />

          <Stack
            sx={{
              left: 0,
              top: 0,
              width: 'calc(100vw - 280px)',
              height: '100vh',
              position: 'absolute',
              pointerEvents: 'none',
            }}
          >
            <Stack direction={'row'}>
              <Head form={form} />
            </Stack>
          </Stack>

          <Box
            sx={{
              mx: 0,
              my: 0,
              position: 'absolute',
              left: 0,
              top: '45vh',
              width: 'calc(100vw - 280px)',
              overflowY: 'scroll',
              backgroundColor: c2.formatHex8(),
              backdropFilter: 'blur(8px)',
              height: '55vh',
              borderLeft: '1px solid black',
            }}
          >
            <Time form={form} />
          </Box>
        </Box>
      </Stack>
    </>
  )
}

export default Dashboard
