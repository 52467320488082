import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    mode: 'light',
    background: { default: '#ECF2FF', light: '#F3F7FF' },
    // success: { main: '#2E7d32', light: '#4caf50', dark: '#1b5e20' },
    // primary: { main: '#EEFFEF', light: '#99FFA0', dark: '#7EDD83' },
    white: ['#FFF', '#F4F4F6', '#DDDDE3', '#C7C7D1', '#6E6E87'],
    // blue: ['#777D85', '#505D73', '#23272F', '#D2D9E7', '#B0B8C9'],
    blue: ['#F4F8FF', '#D3D8E6', '#545A63', '#494C53', '#191A1B'],
    orange: ['#FFB066', '#FF7B00'],

    warning: {
      main: '#FD8421',
      light: '#FFA270',
      contrastText: '#FFFFFF',
    },
    decorate: [
      '#D6C9F8',
      '#DCFADF',
      '#F2BBBB',
      '#05006A',
      '#FF90BD',
      '#FFDA6D',
      '#FF9F46',
      '#B2EEEB',
      '#2CA22F',
    ],
  },
  time: {
    delay: (n) => n,
    duration: (n) => n,
  },
})
