import { useState, useEffect } from 'react'

import _ from 'lodash'
import { Stack, Typography, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export const Head = ({ form }) => {
  const theme = useTheme()

  return (
    <>
      <Box
        sx={{
          m: 2,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 1,
            borderRadius: 4,
            boxShadow: '0px 0px 12px ' + theme.palette.blue[1],
            backgroundColor: 'rgba(255,255,255, .9 )',
            backdropFilter: 'blur(8px)',
          }}
        ></Box>
        {form.watch('current.county') && (
          <Box
            sx={{
              px: 4,
              py: 1,
              opacity: 1,
              zIndex: 2,
              position: 'relative',
            }}
          >
            <div
              style={{
                color: theme.palette.blue[2],
                fontSize: 40,
                fontWeight: 600,
                textTransform: 'capitalize',
                fontFamily: "'Tungsten A', 'Tungsten B'",
                zIndex: 10,
              }}
            >
              {`${_.capitalize(
                form.getValues('current.county')
              )} county, ${_.upperCase(form.getValues('current.state'))}`}
            </div>
          </Box>
        )}
      </Box>
    </>
  )
}
