import { useState, useEffect, useRef } from 'react'
import axios from '../../tools/axiosWrapper'
import { motion } from 'framer-motion'
import { timeParse, timeFormat } from 'd3-time-format'
import { randomInt, randomNormal, randomUniform } from 'd3-random'

import { useTheme } from '@mui/material/styles'
import { useForm } from 'react-hook-form'
import { Canvas } from './canvas'
import { Canvas2 } from './canvas2'

import { color } from 'd3-color'

import _ from 'lodash'
import { Stack, Typography, Box } from '@mui/material'
import { parseTime } from './parse'

export const Time = ({ form }) => {
  const theme = useTheme()
  const [time, setTime] = useState(null)
  const [age, setAge] = useState(false)

  let AGES = [
    '2020to2029',
    '2010to2019',
    '2000to2009',
    '1990to1999',
    '1980to1989',
    '1970to1979',
    '1960to1969',
    '1950to1959',
    '1940to1949',
    '1930to1939',
    '1920to1929',
    '1910to1919',
    '1900to1909',
  ]

  useEffect(() => {
    form.watch('current.state') &&
      axios
        .get('/dashboard/state_overtime', {
          params: {
            state: form.getValues('current.state'),
          },
        })
        .then((res) => setTime(parseTime(res.data)))
  }, [form.watch('current.state')])
  // console.log(time)

  useEffect(() => {
    form.watch('current.county') &&
      axios
        .get('/dashboard/county_overtime', {
          params: {
            state: form.getValues('current.state'),
            county: form.getValues('current.county'),
          },
        })
        .then((res) => setTime(parseTime(res.data)))
  }, [form.watch('current.county')])

  useEffect(() => {
    form.watch('current.zip') &&
      axios
        .get('/dashboard/county_zip_overtime', {
          params: {
            state: form.getValues('current.state'),
            county: form.getValues('current.county'),
            zip: form.getValues('current.zip'),
          },
        })
        .then((res) => {
          // console.log('raw', res.data)
          let chain = _.chain(res.data)
            .uniqBy('lastupdate')
            .map((d) => ({
              ...d,
              date: timeParse('%Y%m%d')(d.lastupdate),
              data: d[form.watch('current.display')],
            }))
            .filter((d) => d.total !== 0)
            .value()

          // console.log('clean', chain)
          setTime(
            form.watch('current.display') == 'change'
              ? chain.splice(0, 30)
              : chain
          )
        })
  }, [form.watch('current.zip')])

  useEffect(() => {
    form.watch('current.state') &&
      axios
        .get('/dashboard/county_age_overtime', {
          params: {
            state: form.getValues('current.state'),
            county: form.getValues('current.county'),
            agerange: form.getValues('current.age'),
          },
        })
        .then((res) => setTime(parseTime(res.data)))
  }, [form.watch('current.age')])

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box
        sx={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          position: 'absolute',
          // borderRadius: 8,
          // boxShadow: '0px 0px 12px ' + theme.palette.blue[1],
        }}
        className={'blur'}
      ></Box>
      <Box
        sx={{
          px: 3,
          py: 1,
          opacity: 1,
          zIndex: 2,
          position: 'relative',
        }}
      >
        <div
          style={{
            opacity: 1,
            fontSize: 40,
            fontWeight: 600,
            textTransform: 'capitalize',
            fontFamily: "'Tungsten A', 'Tungsten B'",
            zIndex: 10,
          }}
        >
          <Stack>
            <Box
              sx={{ borderBottom: `2px solid ${theme.palette.blue[4]}`, pb: 2 }}
            >
              <Typography
                sx={{
                  fontSize: 24,
                  fontWeight: 700,
                  color: theme.palette.blue[2],
                  fontFamily: 'Roboto Mono',
                  textTransform: 'uppercase',
                  pb: 1,
                }}
              >
                {form.watch('current.zip') &&
                  `zipcode ${form.getValues('current.zip')} in `}
                {form.watch('current.county') &&
                  `${_.capitalize(form.getValues('current.county'))} county, `}
                {form.watch('current.state') &&
                  `${_.upperCase(form.getValues('current.state'))}`}
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 700,
                  color: theme.palette.blue[2],
                  fontFamily: 'Roboto Mono',
                  textTransform: 'uppercase',
                }}
              >
                {form.watch('current.age') &&
                  `voters born from ${form
                    .getValues('current.age')
                    .replace('to', ' to ')}`}
              </Typography>
            </Box>
            {time && <Canvas form={form} time={time} tool={true} />}
            {time && (
              <Canvas2
                form={form}
                time={time.map((d) => ({
                  ...d,
                  data:
                    randomNormal(d.total / 1000, 0.1)() +
                    randomInt(d.total / 200, d.total / 100)(),
                }))}
                tool={true}
              />
            )}
          </Stack>
        </div>
      </Box>
    </Box>
  )
}
