import { useState, useEffect, useContext } from 'react'
// import axios from '../../tools/axiosWrapper'
import ReactPlayer from 'react-player'
import { motion } from 'framer-motion'

import { Link } from 'react-router-dom'

import { ReactComponent as BoothSVG } from '../media/booth.svg'
import { Language } from '../context/language'

import TextAnimation from './textanimation/textanimation'

import Video from '../media/vf_2.mov'
import VideoSpanish from '../media/vf_s.mp4'
import axios from '../tools/axiosWrapper'

import './home.css'

const Home = ({}) => {
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    })
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
        })
      }
      window.addEventListener('resize', handleResize)
      handleResize()
      return () => window.removeEventListener('resize', handleResize)
    }, [])
    return windowSize.width > 700 ? 0 : 1
  }

  const mobile = useWindowSize()

  const { language, set_language } = useContext(Language)

  return (
    <main style={{ padding: "64px" }}>
      <div
        style={{
          height: "100%",
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        <div style={{ maxWidth: "600px" }}>
          <h1>
            <span style={{ color: "#EE4B2B" }}>Partner</span> with VoteFlare™
          </h1>

          <h3>
            VoteFlare provides real-time voter protection to improve the civic health
            of your state, county or member organization. Our direct-to-voter model
            helps reach voters when critical changes to their voter record occurs, so
            they can vote on election day. Contact us for more information on how
            we can secure your ability to vote!
          </h3>

          <a className="partner-button" href="https://partners.voteflare.org">Become a Partner</a>
        </div>
        <div>
          <BoothSVG className='booth' />
        </div>
      </div>

    </main>
  )
}

export default Home
