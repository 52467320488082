import { useEffect, useState, useRef } from 'react'
import * as turf from '@turf/turf'
import _ from 'lodash'
import mapboxgl from 'mapbox-gl'
import { default as axios_ } from '../../tools/axiosWrapper'

export const merge = (LINES, POPDATA, VFDATA) =>
  _.chain(LINES)
    .filter((d, i) => d.properties.STATE == '39')
    .forEach((d) => {
      _.set(
        d,
        'properties.DATA.POP',
        _.find(POPDATA, { GEO_ID: d.properties.GEO_ID })
      )
    })
    .forEach((d) => {
      _.set(
        d,
        'properties.DATA.VOTEFLARE',
        VFDATA['OH_' + _.toUpper(d.properties.NAME)]
      )
    })
    .forEach((d) => {
      _.set(
        d,
        'geometry.CENTER',
        d.geometry.type == 'Polygon'
          ? turf.centerOfMass(turf.polygon(d.geometry.coordinates))
          : null
      )
    })
    .filter((d) => d.geometry.CENTER)
    .value()

export const init = (map, cont) =>
  (map.current = new mapboxgl.Map({
    container: cont.current,
    style: 'mapbox://styles/emunn/cle4shdnk003201pdhrvr28ci',
    center: [-82.9, 40.2],
    zoom: 5.85,
    // interactive: false,
    projection: {
      name: 'albers',
      center: [-96, 30],
    },
  }))

export const layer = (map, LINES) => {
  console.log(LINES)
  map.current.addLayer({
    id: 'counties',
    type: 'fill',
    source: {
      type: 'geojson',
      data: { type: 'FeatureCollection', features: LINES },
    },
    layout: {},
    paint: {
      'fill-color': '#FFF',
      'fill-opacity': .5,
      'fill-outline-color': '#000',
    },
  })
}


export const fly = (map, features, county) =>
  map.current.flyTo({
    zoom: 8.2,
    center: features
      .filter((d) => d.properties.NAME == _.capitalize(county))[0]
      .geometry.CENTER.geometry.coordinates.map((d, i) =>
        i ? d - 0.3 : d
      ) || [-96.4, 39],
    speed: 1.5,
    curve: 0.7,
    easing: (t) => t,
    essential: true,
  })

export const remove = (map, state) =>
  map.current
    .getStyle()
    .layers.filter((d) => _.startsWith(d.id, 'main-'))
    .forEach((d) => map.current.removeLayer(d.id))

export const getziplist = async (state, county) =>
  await axios_.get('/dashboard/zips', {
    params: {
      state: state,
      county: county,
    },
  })

export const getzipdata = async (state, county) =>
  await axios_.get('/dashboard/county_zip_now', {
    params: {
      state: state,
    },
  })
