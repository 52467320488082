import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { Controller } from 'react-hook-form'
import { Autocomplete, TextField, Box } from '@mui/material'
import _ from 'lodash'

export const Auto = ({ d, form }) => {
  const theme = useTheme()

  return (
    <>
      <Controller
        key={d.label}
        name={d.label}
        control={form.control}
        render={({ onChange, ...props }) => (
          <Autocomplete
            {...props}
            getOptionLabel={d.optionLabel}
            size={'small'}
            // disabled={d.label == 'state'}
            sx={{ px: 2 }}
            key={d.label}
            options={d.options}
            multiple={false}
            freeSolo={false}
            onChange={d.onChange}
            autoSelect
            renderInput={(params, i) => {
              return (
                <TextField
                  {...params}
                  key={i}
                  label={d.display}
                  variant={'standard'}
                />
              )
            }}
            renderOption={(props, option) => (
              <Box {...props} key={option}>
                {d.optionLabel ? d.optionLabel(option) : option}
              </Box>
            )}
          />
        )}
      />
    </>
  )
}
