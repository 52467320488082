import { useEffect, useState, useRef } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import zooms from '../data/stateZooms.json'
import geography from '../data/county_geography.json'
import POPDATA from '../data/county_populations.json'
import { default as axios_ } from '../../tools/axiosWrapper'
import axios from 'axios'
import { useTheme } from '@mui/material/styles'
import { max, extent } from 'd3-array'
import { randomInt } from 'd3-random'
import { scaleLinear } from 'd3-scale'
import { interpolateOranges, interpolatePurples } from 'd3-scale-chromatic'
import { useWindowSize } from './useWindowSize'

import * as turf from '@turf/turf'
import _ from 'lodash'
import { Box, Button } from '@mui/material'
import { feature, geometry } from '@turf/turf'

import {
  merge,
  init,
  layer,
  fly,
  remove,
  getziplist,
  getzipdata,
} from './tools'

export const Map = ({ form }) => {
  const size = useWindowSize()
  const theme = useTheme()

  mapboxgl.accessToken =
    'pk.eyJ1IjoiZW11bm4iLCJhIjoiY2w2bWoxNWp6MGx6ajNicnVqM3YyZ2M0NiJ9.8CG8a8G7qY6mrF3Yk-Kx1g'

  const mapContainer = useRef(null)
  const map = useRef(null)
  const [LINES, SETLINES] = useState(null)
  const [zips, setZips] = useState(null)

  useEffect(() => {
    if (map.current) return
    init(map, mapContainer)
    map.current.on('load', () => {
      map.current.addLayer({
        id: 'main-',
      })
    })
    map.current.addControl(new mapboxgl.NavigationControl())
  }, [])

  // useEffect(() => {
  //   features && layer(map, features)
  // }, [features])

  useEffect(() => {
    const run = async () => {
      remove(map, true)
      let response = await axios.get(
        'https://raw.githubusercontent.com/OpenDataDE/State-zip-code-GeoJSON/master/oh_ohio_zip_codes_geo.min.json'
      )
      form.setValue('current.features.zips', response.data.features)
      let VFDATA = await axios_.get('/dashboard/counties_now', {
        params: {
          state: form.getValues('current.state'),
        },
      })
      console.log('VFDATA', VFDATA)
      const all = merge(geography.features, POPDATA, VFDATA.data)

      // layer(map, all)
      SETLINES(all)

      let c = (n) =>
        interpolateOranges(
          scaleLinear(
            extent(all, (d) => d.properties.DATA.VOTEFLARE?.total),
            [0.1, 1]
          )(n)
        )

      all.forEach((d) => {
        d.properties.MERGED_color =
          c(d.properties.DATA.VOTEFLARE?.total) || '#FFF'
      })

      map.current.addLayer({
        id: 'main-' + randomInt(0, 1000)(),
        type: 'fill',
        paint: {
          'fill-color': ['get', 'MERGED_color'],
          'fill-opacity': 0.8,
        },
        source: {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: all },
        },
      })

      map.current.addLayer({
        id: 'main-' + randomInt(0, 1000)(),
        type: 'line',
        paint: {
          'line-color': '#FFF',
          'line-width': 3,
        },
        source: {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: all },
        },
      })
    }

    run()
  }, [form.watch('current.state')])

  useEffect(() => {
    const run = async () => {
      remove(map, false)
      // fly(map, LINES, form.watch('current.county'))

      let ziplist = await getziplist(
        form.watch('current.state'),
        form.watch('current.county')
      )

      let zipdata = await getzipdata(form.watch('current.state'))

      zipdata = _.pickBy(zipdata.data, (value, key) =>
        _.startsWith(
          key,
          `${form.watch('current.state')}_${_.upperCase(
            form.watch('current.county')
          )}`
        )
      )

      let zipdata2 = _.map(zipdata, (key, value) => ({ name: value, ...key }))

      let c = (n) =>
        interpolatePurples(
          scaleLinear(
            extent(zipdata2, (d) => d.total),
            [0.1, 1]
          )(n)
        )

      zipdata2.forEach((d) => {
        d.color = c(d.total)
      })

      let all = _.chain(form.getValues('current.features.zips'))
        .filter((d) => _.includes(ziplist.data, d.properties.ZCTA5CE10))
        .forEach((d) => {
          let data = zipdata2.filter(
            (e) =>
              e.name ==
              `${form.watch('current.state')}_${_.upperCase(
                form.watch('current.county')
              )}_${d.properties.ZCTA5CE10}`
          )[0]

          _.forEach(data, (v, k) => _.set(d, `properties.MERGED_${k}`, v))
        })
        .value()

      map.current.addLayer({
        id: 'main-' + randomInt(0, 1000)(),
        type: 'fill',
        paint: {
          'fill-color': ['get', 'MERGED_color'],
          'fill-opacity': 0.4,
        },

        source: {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: all },
        },
      })

      map.current.addLayer({
        id: 'main-' + randomInt(0, 1000)(),
        type: 'line',
        paint: {
          'line-color': '#FFF',
          'line-width': 2,
        },
        source: {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: all },
        },
      })
    }

    run()
  }, [form.watch('current.county')])

  return (
    <>
      <div
        style={{
          height: '45vh',
          width: size.width - 300,
        }}
        ref={mapContainer}
        className='map-container'
      />
    </>
  )
}
