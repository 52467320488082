import { useState, useEffect, useRef } from 'react'
import { Select } from '@mantine/core'
import counties from '../../media/data/counties.json'
import Mapbox from '../map/map'
import axios from '../../tools/axiosWrapper'
import { motion } from 'framer-motion'
import { timeParse, timeFormat } from 'd3-time-format'
import { timeDay, utcTicks } from 'd3-time'
import { extent, min, max } from 'd3-array'
import { line } from 'd3-shape'
import { color } from 'd3-color'

import { scaleTime, scaleLinear } from 'd3-scale'
import { axisBottom } from 'd3-axis'
import * as d3 from 'd3'

import { useTheme } from '@mui/material/styles'
import { useForm } from 'react-hook-form'

import _ from 'lodash'
import { Stack, Typography, Box } from '@mui/material'
import { YTicks } from './yticks'
import { XTicks } from './xticks'
import { Tool } from './tool'
import click1 from './click2.mp3'
import useSound from 'use-sound'

const HEIGHT = 840
const WIDTH = 1920
const MARGIN = 140

export const Canvas2 = ({ form, time, tool }) => {
  const theme = useTheme()
  const [mouseOver, setMouseOver] = useState(0)

  const [play] = useSound(click1, { volume: 0.15 })

  const XSCALE = scaleTime(
    extent(time, (d) => d.date),
    [MARGIN, WIDTH - MARGIN]
  )

  const YSCALE = scaleLinear(
    [0, 2 * max(time, (d) => d.data)],
    [HEIGHT - MARGIN, 20]
  )

  const time2 = time.map((d) => ({
    ...d,
    X: XSCALE(d.date),
    Y: YSCALE(d.data),
  }))

  const svgRef = useRef()
  const LINE = line()
    // .curve(d3.curveBundle.beta(1))
    .curve(d3.curveNatural)

  const PATH =
    LINE(time2.map((d) => [d.X, d.Y])) +
    `L${MARGIN},${HEIGHT - MARGIN}L${WIDTH - MARGIN},${HEIGHT - MARGIN}`

  let c1 = color(theme.palette.blue[2])
  c1.opacity = 0.01

  let c2 = color(theme.palette.blue[2])
  c2.opacity = 0.5

  console.log(time)
  return (
    <Stack sx={{ mt: 1 }}>
      <Box sx={{ height: 50 }}>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 700,
            color: theme.palette.blue[2],
            fontFamily: 'Roboto Mono',
            textTransform: 'uppercase',
          }}
        >
          flares sent
        </Typography>
      </Box>

      <motion.svg
        ref={svgRef}
        onMouseEnter={() => setMouseOver(1)}
        onMouseLeave={() => setMouseOver(0)}
        viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
        style={{
          pointerEvents: 'all',
          height: '40vh',
          maxWidth: 1100,
        }}
      >
        <defs>
          <linearGradient id='gradient' x1='0%' y1='100%' x2='0%' y2='0%'>
            <stop offset='0%' stop-color={c1} />
            <stop offset='80%' stop-color={c2} />
          </linearGradient>
        </defs>
        <motion.path
          style={{
            fill: ' url(#gradient)',
          }}
          inital={{ d: PATH, opacity: 0 }}
          animate={{ d: PATH, opacity: 1 }}
          transition={{ duration: 2 }}
        />

        {time2.map((d, i) => (
          <motion.circle
            r={6}
            fill={theme.palette.blue[2]}
            cx={d.X}
            initial={{ cy: HEIGHT }}
            animate={{
              cy: d.Y,
            }}
            transition={{ type: 'spring', stiffness: 50, delay: i * 0.01 }}
          />
        ))}

        <XTicks
          time={time2}
          HEIGHT={HEIGHT}
          WIDTH={WIDTH}
          MARGIN={MARGIN}
          XSCALE={XSCALE}
        />
        <YTicks
          time={time2}
          HEIGHT={HEIGHT}
          WIDTH={WIDTH}
          MARGIN={MARGIN}
          YSCALE={YSCALE}
        />
        {mouseOver && svgRef && time && tool && (
          <Tool
            svgRef={svgRef}
            HEIGHT={HEIGHT}
            WIDTH={WIDTH}
            MARGIN={MARGIN}
            time={_.sortBy(time2, 'X')}
            play={play}
            form={form}
          />
        )}
      </motion.svg>
    </Stack>
  )
}
