import { useState, useEffect } from 'react'
import { Select } from '@mantine/core'
import axios from '../tools/axiosWrapper'
import { useTheme } from '@mui/material/styles'
import { TextField } from '@mui/material'
import { Auto } from './field/autocomplete'
import _ from 'lodash'
import { Stack, Box } from '@mui/material'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import VolumeMuteIcon from '@mui/icons-material/VolumeMute'
import click3 from './time/click3.mp3'
import useSound from 'use-sound'

import seal from './data/seal.png'

export const Side = ({ form }) => {
  const theme = useTheme()
  const [play] = useSound(click3)

  useEffect(() => {
    axios
      .get('/dashboard/counties', {
        params: { state: form.watch('current.state') },
      })
      .then((res) => {
        form.setValue('options.counties', res.data)
      })
  }, [form.watch('current.state')])

  useEffect(() => {
    axios
      .get('/dashboard/zips', {
        params: {
          state: form.watch('current.state'),
          county: form.watch('current.county'),
        },
      })
      .then((res) => {
        form.setValue('options.zips', res.data)
      })
  }, [form.watch('current.county')])

  const FIELDS = [
    {
      display: 'state',
      label: 'state',
      options: form.watch('options.states'),
      onChange: (e, v) => form.setValue('current.state', v),
      // optionLabel: (o) => _.capitalize(o),
    },
    {
      display: 'county',
      label: 'county',
      options: _.keys(form.watch('options.counties')),
      onChange: (e, v) => form.setValue('current.county', v),
      // optionLabel: (o) => _.capitalize(o),
    },
    {
      display: 'zipcode',
      label: 'zip',
      options: form.watch('options.zips'),
      onChange: (e, v) => form.setValue('current.zip', v),
      // optionLabel: (o) => _.capitalize(o),
    },
    {
      display: 'age',
      label: 'age',
      options: [
        '2020 - 2029',
        '2010 - 2019',
        '2000 - 2009',
        '1990 - 1999',
        '1980 - 1989',
        '1970 - 1979',
        '1960 - 1969',
        '1950 - 1959',
        '1940 - 1949',
        '1930 - 1939',
        '1920 - 1929',
        '1910 - 1919',
        '1900 - 1909',
      ],
      onChange: (e, v) => form.setValue('current.age', v.replace(' - ', 'to')),
      optionLabel: (o) => o,

      // optionLabel: (o) => {
      //   let getFullYear = new Date().getFullYear()
      //   let a = _.chain(o)
      //     .split('to')
      //     .map((d) => getFullYear - +d)
      //     .map((d) => Math.max(d, 0))
      //     .sortBy()
      //     .join(' - ')
      //     .value()

      //   return a
      // },
    },
    // {
    //   display: 'display',
    //   label: 'display',
    //   options: ['change', 'total'],
    //   onChange: (e, v) => form.setValue('current.display', v),
    //   // optionLabel: (o) => _.capitalize(o),
    // },
  ]

  return (
    <>
      <Stack
        sx={{
          height: '100vh',
          width: 280,
        }}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Stack direction={'row'} sx={{ m: 2, py: 2, width: 250 }} spacing={1}>
          <svg
            style={{ width: 34, marginLeft: 20 }}
            viewBox='0 0 56.277 71.993'
          >
            <g>
              <path
                className='logoa'
                d='M54.988,44.924,45.745,29.646a2,2,0,0,0-1.722-.965l-29.742.156a2,2,0,0,0-1.6.809L1.394,44.871A2,2,0,0,0,1,46.063v22.93a2,2,0,0,0,2,2H53.277a2,2,0,0,0,2-2V45.96A2,2,0,0,0,54.988,44.924Z'
              />
              <line
                className='logob'
                x1='40.675'
                y1='36.372'
                x2='16.675'
                y2='36.372'
              />
              <polygon
                id='logocard'
                class='logoa'
                points='38.018 36.372 47.564 20.029 30.065 9.808 15.981 33.918 20.182 36.372 38.018 36.372'
              />

              <line
                className='logoa'
                x1='55.277'
                y1='45.402'
                x2='1'
                y2='45.402'
              />
              <path
                className='logoc'
                d='M15.981,0c1.962,5.394,4.414,7.846,9.808,9.808-5.394,1.961-7.846,4.413-9.808,9.807-1.961-5.394-4.413-7.846-9.807-9.807C11.568,7.846,14.02,5.394,15.981,0Z'
              />
              <path
                className='logoc'
                d='M41.68,18.626a5.373,5.373,0,0,0,1.495,5.061,5.373,5.373,0,0,0-5.061,1.495,5.373,5.373,0,0,0-1.495-5.061A5.373,5.373,0,0,0,41.68,18.626Z'
              />
            </g>
          </svg>
          <div className='logo-text' style={{ fontSize: '30px', marginTop: 5 }}>
            Vote
            <b className='logo-bold'>
              Flare<sup style={{ fontSize: 9 }}>TM</sup>
            </b>
          </div>
        </Stack>
        <Stack
          sx={{
            borderRadius: 8,
            height: 'calc(100vh - 20px)',
            width: 'calc(100% - 20px)',
          }}
          justifyContent={'space-between'}
        >
          <Stack spacing={2}>
            {FIELDS.map((d, i) => (
              <Auto d={d} form={form} />
            ))}
          </Stack>

          <Stack
            sx={{ pb: 2 }}
            onClick={() => {
              // !form.getValues('current.sound') && play()
              // form.setValue('current.sound', !form.getValues('current.sound'))
            }}
          >
            {/* {form.watch('current.sound') ? (
              <VolumeUpIcon />
            ) : (
              <VolumeMuteIcon sx={{ opacity: 0.5 }} />
            )} */}
            <Box>
              <img src={seal} style={{ width: 75 }} />
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
