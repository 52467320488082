import { useState, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import { timeParse, timeFormat } from 'd3-time-format'
import { format } from 'd3-format'

import { timeDay, utcTicks } from 'd3-time'
import { extent, min, max, ascending, descending } from 'd3-array'
import { scaleTime, scaleLinear, scaleQuantize } from 'd3-scale'
import { useMousePosition } from './useMousePosition'
import { useTheme } from '@mui/material/styles'
import _ from 'lodash'

export const Tool = ({ svgRef, HEIGHT, WIDTH, MARGIN, time, play, form }) => {
  const mouse = useMousePosition()
  const theme = useTheme()

  const [I, setI] = useState(null)

  const POS = (p) =>
    scaleLinear(
      [
        svgRef.current.getBoundingClientRect().x,
        svgRef.current.getBoundingClientRect().x +
          svgRef.current.getBoundingClientRect().width,
      ],
      [0, WIDTH]
    )(p)

  const S = scaleQuantize(
    [MARGIN, WIDTH - MARGIN],
    [...Array(time.length).keys()]
  )

  useEffect(() => {
    setI(S(POS(mouse.x)))
  }, [mouse])

  useEffect(() => {
    form.getValues('current.sound') && play()
    // return () =>
  }, [I])

  const X = time.map((d) => d.X)[I]
  const Y = time.map((d) => d.Y)[I]

  return (
    <>
      <rect
        x={WIDTH - 130}
        y={Y - 25}
        height={40}
        width={205}
        style={{
          fill: theme.palette.background.default,
        }}
      />
      <motion.text
        initial={{ x: WIDTH, y: HEIGHT }}
        animate={{ x: WIDTH, y: Y }}
        style={{
          fontSize: 26,
          fontWeight: '600',
          fill: theme.palette.orange[0],
          textAnchor: 'end',
          fontFamily: 'Roboto Mono',
          alignmentBaseline: 'middle',
        }}
        transition={{ ease: 'easeInOut', duration: 0.08 }}
      >
        {format(',')(time.map((d) => d.data)[I])}
      </motion.text>
      <motion.path
        animate={{ d: `M ${WIDTH - 142} ${Y} L ${X} ${Y}` }}
        style={{
          stroke: theme.palette.orange[0],
          strokeWidth: 8,
        }}
        transition={{ ease: 'easeInOut', duration: 0.08 }}
      />
      <motion.text
        initial={{ x: WIDTH, y: HEIGHT }}
        animate={{ x: X, y: HEIGHT - 90 }}
        style={{
          fontSize: 26,
          fontWeight: '600',
          fill: theme.palette.orange[0],
          textAnchor: 'middle',
          fontFamily: 'Roboto Mono',
        }}
        transition={{ ease: 'easeInOut', duration: 0.08 }}
      >
        {timeFormat('%d %b %Y')(time.map((d) => d.date)[I])}
      </motion.text>
      <motion.path
        animate={{ d: `M ${X} ${HEIGHT - 142} L ${X} ${Y}` }}
        style={{
          stroke: theme.palette.orange[0],
          strokeWidth: 8,
        }}
        transition={{ ease: 'easeInOut', duration: 0.08 }}
      />
      <motion.circle
        animate={{ cx: X, cy: Y }}
        r={12}
        style={{
          fill: theme.palette.orange[1],
        }}
        transition={{ ease: 'easeInOut', duration: 0.08 }}
      />
    </>
  )
}
