import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { format } from 'd3-format'
import { timeDay, utcTicks } from 'd3-time'
import { extent, min, max, range } from 'd3-array'
import { scaleTime, scaleLinear } from 'd3-scale'
import { axisBottom } from 'd3-axis'

import { Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import _ from 'lodash'
import * as d3 from 'd3'

export const YTicks = ({ time, HEIGHT, WIDTH, MARGIN, YSCALE }) => {
  const theme = useTheme()

  const A = YSCALE.nice().ticks(4)

  return (
    <>
      {time &&
        A.map((d, i) => (
          <motion.text
            style={{
              fontSize: 26,
              fontWeight: '600',
              fill: theme.palette.blue[2],
              textAnchor: 'end',
              fontFamily: 'Roboto Mono',
            }}
            x={WIDTH}
            y={YSCALE(d)}
          >
            {format(',')(d)}
          </motion.text>
        ))}
    </>
  )
}
