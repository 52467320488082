import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { format } from 'd3-format'
import { timeFormat } from 'd3-time-format'

import { timeDay, utcTicks } from 'd3-time'
import { extent, min, max } from 'd3-array'
import { scaleTime, scaleLinear } from 'd3-scale'
import { Stack, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import _ from 'lodash'

export const XTicks = ({ time, HEIGHT, WIDTH, MARGIN, XSCALE }) => {
  const theme = useTheme()

  const getTick = (n) =>
    scaleLinear(
      [0, 5],
      extent(time, (d) => d.date)
    )(n)

  const TICKS = _.chain([...Array(6).keys()])
    .map((d, i) => getTick(d))
    .uniq()
    .value()

  return (
    <>
      {TICKS.map((d, i) => {
        const X = XSCALE(d)
        const Y = HEIGHT - 10

        return (
          <>
            <motion.path
              d={`M ${X} ${Y - 40} L ${X} ${Y - 60}`}
              style={{ stroke: theme.palette.blue[2], strokeWidth: 5 }}
            />
            <motion.text
              style={{
                fontSize: 26,
                fill: theme.palette.blue[2],
                fontWeight: '600',
                textAnchor: 'middle',
                fontFamily: 'Roboto Mono',
              }}
              x={X}
              y={Y}
            >
              {timeFormat('%b %y')(d)}
            </motion.text>
          </>
        )
      })}
    </>
  )
}
