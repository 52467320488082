import { useState, useEffect, useRef } from 'react'
import { timeParse, timeFormat } from 'd3-time-format'
import _ from 'lodash'

export const parseTime = (data) =>
  _.chain(data)
    .uniqBy('lastupdate')
    .map((d) => ({
      ...d,
      date: timeParse('%Y%m%d')(d.lastupdate),
      data: d['total'],
    }))
    .filter((d) => d.total !== 0)
    .value()
